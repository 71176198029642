import React, { useRef, useEffect } from "react";
import styled from "styled-components";
const Box = styled.div`
  position: absolute;
  top: 0px;
z-index:1;
  @media (max-width: 700px) {
    left: 20px;
    
  }
`;
const Bulb = styled.svg`
  position: absolute;
  width: 16px;
  height: 16px;
  top: 12px;
  left: 56.5px;
  display: ${(p) => p.theme.pullyDisplay}!important;
  filter: drop-shadow(#F7DE3A -5px 7px 10px);
`;
const Icon = styled.svg`
  /* flex: none;
  transition: fill 0.25s;
  width: 48px;
  height: 48px; */
  path:nth-child(1) {
    fill: ${(p) => p.theme.pullyColor};
  }
  path:nth-child(2) {
    fill: ${(p) => p.theme.pullyColor};
  }
  path:nth-child(2) {
    fill: ${(p) => p.theme.pullyColor};
  }
  path:nth-child(4) {
    fill: ${(p) => p.theme.pullyColor};
  }
  path:nth-child(5) {
    fill: ${(p) => p.theme.pullyColor};
  }
  path:nth-child(8) {
    fill: ${(p) => p.theme.bodyBackgroundColor};
  }
`;
const InnerBox = styled.div`
  /* display:${(p) => p.theme.pully} !important; */
  position: absolute;
  left: 50px;
  top: 5px;
`;
const PullBox = styled.div`
  position: absolute;
  top: 11.5px;
  left: 28.5px;
  z-index:0;
`;
const Pully = ({ trigger }) => {
  const canvasRef = useRef(null);
  useEffect(() => {
    class Particle {
      constructor(x, y, mass, isPinned) {
        this.x = x;
        this.y = y;
        this.prevX = x;
        this.prevY = y;
        this.mass = mass;
        this.isPinned = isPinned;
        this.initX = x;
        this.initY = y;
      }
      update(deltaTime, acceleration) {
        const prevPosition = { x: this.x, y: this.y };
        this.x =
          this.x * 2 - this.prevX + acceleration.x * (deltaTime * deltaTime);
        this.y =
          this.y * 2 - this.prevY + acceleration.y * (deltaTime * deltaTime);
        this.prevX = prevPosition.x;
        this.prevY = prevPosition.y;
        if (this.isPinned) {
          this.x = this.initX;
          this.y = this.initY;
        }
      }
    }
    class Stick {
      constructor(p1, p2, length) {
        this.p1 = p1;
        this.p2 = p2;
        this.length = length;
      }
    }
    function getDistance(p1, p2) {
      const dx = p1.x - p2.x;
      const dy = p1.y - p2.y;
      return Math.sqrt(dx * dx + dy * dy);
    }
    function getLength(v) {
      return Math.sqrt(v.x * v.x + v.y * v.y);
    }
    function getDifference(p1, p2) {
      return {
        x: p1.x - p2.x,
        y: p1.y - p2.y,
      };
    }
    function keepInsideView(particle, width, height) {
      if (particle.y >= height) particle.y = height;
      if (particle.x >= width) particle.x = width;
      if (particle.y < 0) particle.y = 0;
      if (particle.x < 0) particle.x = 0;
    }
    function setup(onDragged) {
      const canvas = canvasRef.current;
    
canvas.width= "200";
canvas.height= "200";
canvas.style.width="100px";
canvas.style.height="100px";
canvas.getContext("2d").scale(2,2);
  const ctx = canvas.getContext("2d");
 
      ctx.strokeStyle = "#9a9a9a";
      ctx.fillStyle = "#7289de";
      ctx.lineWidth = 1;
      let mouseX = 0;
      let mouseY = 0;
      const width = canvas.width;
      const height = canvas.height;
      let previousTimeStamp = 0;
      let attachmentThreshold = 30;
      let pullVal = 0;
      let mouseDown = false;
      let attachedToMouse = false;
      const handleSize = 5;
      const particleSize = 0.7;
      const GRAVITY = 20;
      const dragStart = { x: 0, y: 0 };
      const particleMass = 10_000;
      const handleMass = 20_000;
      const dragThreshold = 35;
      const numParticles = 20;
      const ropeLength = 20;
      const constraintIterations = 15;
      const particles = [];
      const sticks = [];
      const startX = width / 4;
      let angle = 0.01;
      const force = {
        x: GRAVITY * Math.cos(((90 - angle) * Math.PI) / 180),
        y: GRAVITY * Math.sin(((90 - angle) * Math.PI) / 180),
      };
      // window.addEventListener(
      //   "deviceorientation",
      //   (e) => {
      //     angle = e.alpha;
      //     force.x = GRAVITY * Math.cos(((90 - angle) * Math.PI) / 180);
      //     force.y = GRAVITY * Math.sin(((90 - angle) * Math.PI) / 180);
      //   },
      //   true
      // );
      document.addEventListener("visibilitychange", () => {
        if (document.hidden) {
          previousTimeStamp = 0;
        }
      });
      const handleTouchMove = (e) => {
        const rect = canvas.getBoundingClientRect();
        mouseX = e.touches[0].clientX - rect.x;
        mouseY = e.touches[0].clientY - rect.y;
        if (attachedToMouse) {
          const distanceDragged = getDistance(dragStart, {
            x: e.touches[0].clientX - rect.x,
            y: e.touches[0].clientY - rect.y,
          });
          if (distanceDragged > dragThreshold) {
            mouseDown = false;
            attachedToMouse = false;
            onDragged();
          
          } else {
            pullVal = distanceDragged;
          }
        }
      };
      const handleMouseMove = (e) => {
        e.preventDefault();
        mouseX = e.offsetX;
        mouseY = e.offsetY;
        if (attachedToMouse) {
          const distanceDragged = getDistance(dragStart, {
            x: e.offsetX,
            y: e.offsetY,
          });
          if (distanceDragged > dragThreshold) {
            mouseDown = false;
            attachedToMouse = false;
            onDragged();
          }
        }
      };
      const handleMouseDown = (e) => {
        e.preventDefault();
        mouseDown = true;
        dragStart.x = e.offsetX;
        dragStart.y = e.offsetY;
        // if(!attachedToMouse && mouseDown===false){
        //   trigger();
        // }
      };
      const handleMouseUp = (e) => {
        const distanceDragged = getDistance(dragStart, {
          x: e.offsetX,
          y: e.offsetY,
        });
        if(attachedToMouse && mouseDown){
    
          mouseDown=false;
          attachedToMouse=false;
          if (distanceDragged < 1) {
            trigger();
          }
           
        }
     
   
      };
      const handleTouchEnd = () => {
       
   console.log(pullVal);
   console.log(mouseDown);
   console.log(attachedToMouse);
   if (pullVal < 1 && mouseDown) {
    trigger();
  }
          mouseDown=false;
          attachedToMouse=false;
        
    
      };
      const handleTouchStart = (e) => {
        e.preventDefault();
        pullVal=0;
        mouseDown = true;
        const rect = canvas.getBoundingClientRect();
        dragStart.x = e.touches[0].clientX - rect.x;
        dragStart.y = e.touches[0].clientY - rect.y;
        const distanceDragged = getDistance(dragStart, {
          x: e.touches[0].clientX - rect.x,
          y: e.touches[0].clientY - rect.y,
        });
        console.log(distanceDragged)
      };
      canvas.addEventListener("mousemove", handleMouseMove);
      canvas.addEventListener("mousedown", handleMouseDown);
      canvas.addEventListener("mouseup", handleMouseUp);
      canvas.addEventListener("touchmove", handleTouchMove);
      canvas.addEventListener("touchstart", handleTouchStart);
      canvas.addEventListener("touchend", handleTouchEnd);
      // Create particles and sticks
      for (let i = 0; i < numParticles; i++) {
        const particle = new Particle(
          startX,
          i * (ropeLength / numParticles),
          i < numParticles - 1 ? particleMass : handleMass,
          i === 0
        );
        particles.push(particle);
        let stickStartPoint =
          i > 0 ? particles[i - 1] : { x: startX - 0.01, y: 0 };
        const stick = new Stick(
          stickStartPoint,
          particles[i],
          getDistance(stickStartPoint, particles[i])
        );
        sticks.push(stick);
      }
      const draw = (timestamp) => {
        const elapsed = previousTimeStamp ? timestamp - previousTimeStamp : 1;
        previousTimeStamp = timestamp;
        ctx.clearRect(0, 0, width, height);
        const distanceToHandle = getDistance(particles[numParticles - 1], {
          x: mouseX,
          y: mouseY,
        });
        if (distanceToHandle < attachmentThreshold && mouseDown) {
          attachedToMouse = true;
        }
        if (attachedToMouse) {
          particles[numParticles - 1].x = mouseX;
          particles[numParticles - 1].y = mouseY;
        }
        if (distanceToHandle < attachmentThreshold) {
          canvas.style.cursor = "pointer";
        } else {
          canvas.style.cursor = "default";
        }
        // Update particle positions
        for (let i = 0; i < particles.length; i++) {
          const particle = particles[i];
          const acceleration = {
            x: force.x / particle.mass,
            y: force.y / particle.mass,
          };
          particle.update(elapsed, acceleration);
          keepInsideView(particle, width, height);
        }
        // Apply stick constraints to particles
        for (let j = 0; j < constraintIterations; j++) {
          for (let i = 0; i < sticks.length; i++) {
            const stick = sticks[i];
            const diff = getDifference(stick.p1, stick.p2);
            const diffLength = getLength(diff);
            const diffFactor = ((stick.length - diffLength) / diffLength) * 0.5;
            const offset = { x: diff.x * diffFactor, y: diff.y * diffFactor };
            if (!stick.p1.isPinned) {
              // Move points toward each other
              stick.p1.x += offset.x;
              stick.p1.y += offset.y;
            }
            stick.p2.x -= offset.x;
            stick.p2.y -= offset.y;
          }
        }
        // Draw sticks
        for (let i = 0; i < sticks.length; i++) {
          drawLine(
            ctx,
            sticks[i].p1.x,
            sticks[i].p1.y,
            sticks[i].p2.x,
            sticks[i].p2.y
          );
        }
        // Draw particles
        for (let i = 0; i < particles.length; i++) {
          const isHandle = i === particles.length - 1;
          drawCircle(
            ctx,
            particles[i].x,
            particles[i].y,
            isHandle ? handleSize : particleSize,
            isHandle
          );
        }
        window.requestAnimationFrame(draw);
      };
      window.requestAnimationFrame(draw);
    }
    const handler = () => {
      trigger();
    };
    setup(handler);
    function drawCircle(ctx, x, y, radius, fill) {
      ctx.beginPath();
      ctx.arc(x, y, radius, 0, 2 * Math.PI);
      if (fill) {
        ctx.fill();
      } else {
        ctx.stroke();
      }
    }
    function drawLine(ctx, startX, startY, endX, endY) {
      ctx.beginPath();
      ctx.moveTo(startX, startY);
      ctx.lineTo(endX, endY);
      ctx.stroke();
    }
// eslint-disable-next-line
  }, []);
  return (
    <div>
      <Box>
        <Bulb
          viewBox="0 0 93 93"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M46.5 93C72.1812 93 93 72.1812 93 46.5C93 20.8188 72.1812 0 46.5 0C20.8188 0 0 20.8188 0 46.5C0 72.1812 20.8188 93 46.5 93Z"
            fill="#F7DE3A"
          />
        </Bulb>
        <InnerBox>
          <Icon
            width="55"
            height="50"
            viewBox="0 0 455 504"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M377.179 454.404H360.762V242.044L357.706 234.801L194.164 71.2591L205.773 59.6511L371.583 225.462L377.179 238.723V454.404Z"
              fill="#99aab5"
            />
            <path
              d="M244.392 63.4434L196.016 111.83C212.794 147.193 211.525 188.98 192.196 223.368H170.307L26.843 58.0039C61.2314 38.6755 103.018 37.4059 138.37 54.1842L186.757 5.80835L244.392 63.4434Z"
              fill="#99aab5"
            />
            <path
              d="M26.843 58.0039L192.196 223.368C186.888 232.824 180.201 241.723 172.157 249.767L0.433289 78.0438C8.47768 69.9994 17.3867 63.3121 26.843 58.0039Z"
              fill="#676767"
            />
            <path
              d="M410.013 454.404H393.596V241.573L386.317 216.979L180.947 11.608L192.556 0L400.876 208.319L410.013 239.194V454.404Z"
              fill="#99aab5"
            />
            <path
              d="M444.911 474.649C439.263 457.498 419.869 443.456 401.81 443.456H206.031C187.972 443.456 168.578 457.498 162.93 474.649L153.332 503.795H454.509L444.911 474.649Z"
              fill="#99aab5"
            />
            <path
              d="M385.393 219.45C389.913 219.45 393.602 223.127 393.602 227.659C393.602 232.179 389.913 235.867 385.393 235.867C380.862 235.867 377.185 232.179 377.185 227.659C377.185 223.127 380.862 219.45 385.393 219.45Z"
              fill="#FFCC75"
            />
            <path
              d="M385.388 265.964C406.544 265.964 423.694 248.814 423.694 227.657C423.694 206.501 406.544 189.351 385.388 189.351C364.231 189.351 347.081 206.501 347.081 227.657C347.081 248.814 364.231 265.964 385.388 265.964Z"
              fill="#676767"
            />
            <path
              d="M385.388 235.866C380.862 235.866 377.179 232.183 377.179 227.658C377.179 223.132 380.862 219.449 385.388 219.449C389.913 219.449 393.596 223.132 393.596 227.658C393.596 232.183 389.913 235.866 385.388 235.866Z"
              fill="#FFCC75"
            />
          </Icon>
        </InnerBox>
        <PullBox>
          <canvas  ref={canvasRef} {...trigger} />
        </PullBox>
      </Box>
    </div>
  );
};
export default Pully;