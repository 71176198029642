const theme = {
  id: 'dark',
  primaryColor: '#23272a',
  secondaryColor: '#7289da',
  textBodyBackground: '#23272a',
  textBodyFontColor: '#ffffff',
  bodyBackgroundColor: '#2c2f33',
  bodyBackgroundSecondaryColor: '#99aab5',
  bodyFontColor: '#ffffff',
  borderColor: '#2c2f33',
  headerColor: '#2C2F33B3',
  waveColor: 'rgba(35, 39, 42, 1)',
  pullyColor: '#FFFFFF',
  pullyDisplay: 'none'
};

export default theme;