import React from "react";
// import DarkModeToggle from "react-dark-mode-toggle";
import Pully from "../extras/Pully";
export const Switch = (props) => {

    const ThemeSwitcher = () => {

          props.clicked('hoi');
       
        }
    return (
      <div>
         
        {/* <DarkModeToggle
      onChange={ThemeSwitcher}
      checked={isDarkMode}
      size={60}
    /> */}
    <Pully trigger={() => ThemeSwitcher()}/>
    </div>
    );
}