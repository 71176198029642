const theme = {
  id: 'light',
  primaryColor: '#e5e5e5',
  secondaryColor: '#23272a',
  textBodyBackground: '#23272a',
  textBodyFontColor: '#ffffff',
  bodyBackgroundColor: '#FFFFFF',
  bodyBackgroundSecondaryColor: '#99aab5',
  bodyFontColor: '#23272a',
  borderColor: '#FFFFFF',
  headerColor: '#FFFFFFb3',
  waveColor: 'rgba(229, 229, 229, 1)',
  pullyColor: ''
};

export default theme;