import styled from 'styled-components';
import {Switch} from './Switch';
import Headroom from 'react-headroom';
import Fade from 'react-reveal/Fade';
import { useEffect, useState } from "react";
const Wrapper = styled.div`
background:${p=>p.theme.headerColor} !important;
width:100%;

backdrop-filter: saturate(180%) blur(20px); 
&:hover {
  background:${p=>p.theme.bodyBackgroundColor} !important;
}
`;

const LogoBox = styled.div`
width: 12%;
display:flex;
justify-content:flex-start;
align-items:flex-end;
@media(min-width:600px) {
    justify-content:flex-start;
    width:80%;
}
@media(min-width:900px) {
    justify-content:center;
    width:30%;
}
`;

const Logo = styled.h3`
font-size: 1.5em;
font-weight: 600;
color: ${p=>p.theme.secondaryColor};
margin-bottom: 1px;
padding-left : 12px;
cursor:pointer;
`;

const LinkBox = styled.div`
display: flex;
justify-content:flex-end;
width: 88%;
max-width:1200px;
align-items:flex-end ;
@media(min-width:400px) {
    justify-content:flex-end;
}
@media(min-width:700px) {
    justify-content:space-evenly;
    width:90%;
}
@media(min-width:900px) {
    justify-content:flex-end;
    width:60%;
}
`;


const Link = styled.p`
font-weight: 600;
color:${p=>p.theme.bodyFontColor};
margin-right: ${p=>p.last ? '12px' : '15px'};
margin-top: 15px;
cursor:pointer;
margin-bottom:5px;
`;

const Container = styled.div`
display:flex;
align-items:end;
`;

const Button = styled.div`
display:flex;
padding-left: 15px;
outline:none;
pointer-events:${props => (props.pin)}; ;
`;

export const Header = (props) => {
  const [pin, setPin] = useState('auto');
    useEffect(() => {
  
     
    }, [pin])
   return(
     <Headroom onPin={() => setPin('auto')} onUnpin={() => setPin('none')}>
       <Fade top>
     <Wrapper>
           <Container>
           <LogoBox>
    <Logo onClick={props.homeclick}>
       SL
        </Logo> 
        <Button pin={pin}>
        <Switch clicked={props.switch} theme={props.theme}/>
          </Button>
        </LogoBox>
          <LinkBox>
          <Link onClick={props.aboutclick}>
          About.
          </Link>
          <Link onClick={props.creationsclick}>
          Works.
          </Link>
          <Link last onClick={props.contactclick}>
          Contact.
          </Link>
          </LinkBox>
          </Container>
       </Wrapper>
       </Fade>
       </Headroom>
   ); 
}