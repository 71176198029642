import styled from "styled-components";
import { FaGithub, FaExternalLinkAlt } from "react-icons/fa";
import GifPlayer from "react-gif-player";
import playicon from "../../assets/playicon.png";
import Fade from "react-reveal/Fade";
const ProjectContainer = styled.div`
  display: flex;
  align-items: center;  position: sticky;

  transform-origin: center top;
  margin-top:15px;
  background-color:${(p) => p.theme.primaryColor} !important;
  padding:1em;
  border-radius:10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  @media (min-width: 1000px) {
    margin-top: 30px;
    margin-bottom: 30px;
  }
`;
const Subtitle = styled.p`
  opacity: 1;
  font-size: 2.5em;
  margin-top:0;
  margin-bottom: 0;
  font-weight: 600;
  color: ${(p) => p.theme.secondaryColor};

`;
const ProjectBox = styled.div`
  border-radius: 6px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-color: ${(p) => p.theme.bodyFontColor} !important;
  border-left: ${(p) => (p.flip ? "none" : "2px solid")};
  border-right: ${(p) => (p.flip ? "2px solid" : "none")};
  padding-left: ${(p) => (p.flip ? "0px" : "20px")};
  padding-right: ${(p) => (p.flip ? "20px" : "0px")};
`;

const ProjectHead = styled.div`
  text-align: ${(p) => (p.flip ? "right" : "left")};
`;

const ProjectBody = styled.div`
  padding: 12px;
  padding-top: 1px;
  padding-bottom: 1px;
  border-radius: 6px;
  background: ${(p) => p.theme.secondaryColor} !important;
  text-align: ${(p) => (p.flip ? "right" : "left")};
  max-width: 400px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14),
    0px 1px 1px 0 rgba(0, 0, 0, 0.12), 0px 3px 4px -1px rgba(0, 0, 0, 0.4);
  &:hover {
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14),
      0 1px 18px 0 rgba(0, 0, 0, 0.12), 0px 3px 10px -1px rgba(0, 0, 0, 0.4);
  }
  @media (min-width: 700px) {
    max-width: 700px;
  }
  @media (min-width: 1000px) {
    max-width: 1000px;
  }
`;

const ProjectFooter = styled.div``;

const ProjectTitle = styled.p`
  font-weight: 800;
  font-size: 1.8em;
  margin-bottom: 10px;
  margin-top: 0px;
`;

const ProjectText = styled.p`
  color: ${(p) => p.theme.primaryColor};
  font-weight:700;
`;

const ProjectTechBox = styled.div`
  display: flex;
  justify-content: ${(p) => (p.flip ? "flex-end" : "flex-start")} !important;
`;

const ProjectIconBox = styled.div`
  padding-right: 10px;
  cursor: pointer;
`;

const ProjectTechText = styled.p`
  padding-right: 15px;
  font-weight: 700;
`;

const ProjectIcons = styled.div`
  display: flex;
  justify-content: ${(p) => (p.flip ? "flex-end" : "flex-start")} !important;
  color: ${(p) => p.theme.secondaryColor};
`;
const GifBox = styled.div`
  height: 220px;
  width: 475px;
  margin-left: ${(p) => (p.flip ? "30px" : "0px")};
  margin-right: ${(p) => (p.flip ? "0px" : "30px")};
  display: none;
  cursor: pointer;
  align-items: center;
  @media (min-width: 1000px) {
    display: flex;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14),
      0px 1px 1px 0 rgba(0, 0, 0, 0.12), 0px 3px 4px -1px rgba(0, 0, 0, 0.4);
    &:hover {
      box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14),
        0 1px 18px 0 rgba(0, 0, 0, 0.12), 0px 3px 10px -1px rgba(0, 0, 0, 0.4);
    }
    background-image: ${playicon};
  }
`;

export const Project = (props) => {
  return (
    <div style={{marginTop: 1 + 'em'}} className="stack-cards__item js-stack-cards__item">
      <div  >  </div>
      <Fade bottom>
      {props.first ?  <Subtitle  >Works.</Subtitle> : <Subtitle style={{paddingTop: 1.4 + 'em'}}></Subtitle>}
           </Fade>
    

    <ProjectContainer>
      <GifBox>
        <GifPlayer gif={props.gif} still={props.image} />
      </GifBox>
      <ProjectBox>
        <ProjectHead>
          <ProjectTitle>{props.title}</ProjectTitle>
        </ProjectHead>
        <ProjectBody>
          <ProjectText>{props.text}</ProjectText>
        </ProjectBody>
        <ProjectFooter>
          <ProjectTechBox>
            <ProjectTechText>{props.tech}</ProjectTechText>
            <ProjectTechText>{props.tech2}</ProjectTechText>
          </ProjectTechBox>
          <ProjectIcons>
            <ProjectIconBox>
              <FaGithub onClick={props.gitclick} fontSize={24} />{" "}
            </ProjectIconBox>
            <ProjectIconBox>
              {" "}
              <FaExternalLinkAlt fontSize={22} onClick={props.linkclick} />
            </ProjectIconBox>
          </ProjectIcons>
        </ProjectFooter>
      </ProjectBox>
    </ProjectContainer>
    </div>
  );
};

export const ProjectAlt = (props) => {
  return (
    <div className="stack-cards__item js-stack-cards__item">
    <ProjectContainer>
      <ProjectBox flip>
        <ProjectHead flip>
          <ProjectTitle>{props.title}</ProjectTitle>
        </ProjectHead>
        <ProjectBody flip>
          <ProjectText>{props.text}</ProjectText>
        </ProjectBody>
        <ProjectFooter>
          <ProjectTechBox flip>
            <ProjectTechText>{props.tech}</ProjectTechText>
            <ProjectTechText>{props.tech2}</ProjectTechText>
          </ProjectTechBox>
          <ProjectIcons flip>
            <ProjectIconBox>
              <FaGithub fontSize={24} onClick={props.gitclick} />{" "}
            </ProjectIconBox>
            <ProjectIconBox>
              {" "}
              <FaExternalLinkAlt fontSize={22} onClick={props.linkclick} />
            </ProjectIconBox>
          </ProjectIcons>
        </ProjectFooter>
      </ProjectBox>
      <GifBox flip>
        <GifPlayer gif={props.gif} still={props.image} />
      </GifBox>
    </ProjectContainer>
    </div>
  );
};